import React from "react"
import Layout from "../components/Layout"
import alAboutPic from "../../static/assets/about/alAboutPic.jpg"

import * as AboutStyles from "../styles/about.module.css"

export default function About() {
  return (
    <Layout>
      <h2 className={AboutStyles.title}>About</h2>

      <section className={AboutStyles.aboutBody}>
        <div className={AboutStyles.aboutImageSet}>
          <img
            src={alAboutPic}
            alt="alAboutPic"
            className={AboutStyles.aboutImage}
          />
          <p className={AboutStyles.aboutImageCaption}>
            Visiting Proclamation Monument in Jakarta (A. Hynd, March 2023)
          </p>
        </div>

        <div className={AboutStyles.aboutText}>
          <p>
            I am a PhD candidate in international relations at the University of
            New South Wales (UNSW) in Sydney, Australia. I am also a
            Non-resident James A. Kelly Korea Fellow at Pacific Forum, a
            Research Associate at UNSW's Korea Research Initiatives (KRI@UNSW),
            and an Associate Fellow at the Higher Education Academy (AFHEA).
            From January to April 2023, I was a Visiting Fellow at the Centre
            for Strategic and International Studies (CSIS) Indonesia.
          </p>
          <p>
            As a researcher and analyst, I focus on the foreign policy
            strategies of medium-sized states in the Asia-Pacific —
            predominantly Korea, Indonesia and Australia. I have written on
            diverse topics, including nuclear non-proliferation and disarmament,
            maritime security, and clean energy transitions. I also have five
            years professional experience working in South Korea's political
            risk industry, most recently as part of the top-10 law firm DR & AJU
            LLC.
          </p>
          <p>
            My academic research has been published in Environment and Planning
            C: Politics and Space, North Korean Review, and others. My analysis
            and commentary has been published in NK News, The Korea Times, The
            Jakarta Post, Australian Outlook, The Lowy Interpreter, The
            Conversation, Asia Society, Channel News Asia, among others.
          </p>
        </div>
      </section>
    </Layout>
  )
}
